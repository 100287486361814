<template>
  <div>
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg4 pr-6 pl-6>
          <v-layout row wrap justify-end>
            <v-menu
                v-model="menuStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                    v-model="computedStartDateFormatted"
                    label="Từ ngày"
                    hint="DD-MM-YYYY"
                    persistent-hint
                    prepend-icon="fa-calendar-alt"
                    readonly
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="dateRange.startDate" no-title @input="menuStart = false"></v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex lg4 pr-6>
          <v-layout row wrap justify-end>
            <v-menu
                v-model="menuEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                    v-model="computedEndDateFormatted"
                    label="Đến ngày"
                    hint="DD-MM-YYYY"
                    persistent-hint
                    prepend-icon="fa-calendar-alt"
                    readonly
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="dateRange.endDate" no-title @input="menuEnd = false"></v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex lg4 pr-6>
            <v-btn @click="filter_data" color="#a77800" outlined>Xem</v-btn>
        </v-flex>
        <v-flex lg12>
            <v-card class="mb-8">
                <v-data-table fixed-header height="750px"   hide-default-footer :items="items" :server-items-length="items ? items.length : 0" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header>
                        <thead class="v-data-table-header">
                            <tr>
                                <th role="columnheader" class="text-start">
                                <span>Ngày</span>
                                </th>
                                <template v-for="(value, name) in dic_staff">
                                    <th role="columnheader" class="text-end"  v-bind:key="name">
                                        <span>{{value}}</span>
                                    </th>
                                </template>
                                <th role="columnheader" class="text-end">
                                    <span>Tổng</span>
                                </th>
                                <th v-if="adminRole" role="columnheader" class="text-end">
                                    <span>Thực chi</span>
                                </th>
                                <th v-if="adminRole" role="columnheader" class="text-end">
                                    <span>Chênh lệch</span>
                                </th>
                                <th v-if="adminRole" role="columnheader" class="text-end">
                                    <span>Lãi tỷ giá</span>
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>{{item.BuyDate.toString().replace(/(\d{4})(\d{2})(\d{2})/, "$3-$2-$1")}}</td>
                            <template v-for="(value, name) in dic_staff">
                                <td class="text-end" v-bind:key="name">{{ item[name] | currency('¥', 2)}}</td>
                            </template>
                            <td class="text-end">{{ item['Total'] | currency('¥', 2)}}</td>
                            <!-- <td class="text-end" v-if="!item.Editable && adminRole">{{ item.ActualCost | currency('¥',2)}}</td> -->
                            <td class="text-end" v-if="adminRole">
                                <v-edit-dialog :return-value.sync="item.ActualCost" large lazy persistent @save="saveItem(item)">
                                <div>{{ item.ActualCost | currency('',2)}}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.ActualCost" type="number" step=".1" label="Thực chi" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td v-if="adminRole" class="text-end" v-bind:class="{'red--text': item['Diff'] < 0, 'green--text': item['Diff'] > 0,}">{{ item['Diff'] | currency('¥', 2)}}</td>
                            <td v-if="adminRole" class="text-end">{{ item['Revenue'] | currency}}</td>
                        </tr>
                    </template>
                    <template v-slot:body.append>
                        <tr>
                            <td class="font-weight-bold">TỔNG</td>
                            <template v-for="(value, name) in dic_staff">
                                <td class="font-weight-bold text-end" v-bind:key="name">{{ getSummary(Number(name)) | currency('¥', 2)}}</td>
                            </template>
                            <td class="font-weight-bold text-end">{{ getSummary('Total') | currency('¥', 2)}}</td>
                            <td v-if="adminRole" class="font-weight-bold text-end">{{ getSummary('ActualCost') | currency('¥', 2)}}</td>
                            <td v-if="adminRole" class="font-weight-bold text-end">{{ getSummary('Diff') | currency('¥', 2)}}</td>
                            <td v-if="adminRole" class="font-weight-bold text-end">{{ getSummary('Revenue') | currency}}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import { request_list } from '@/commons/hpod';
import Vue from 'vue';
import _ from "lodash";
import moment from "moment"
export default {
    data() {
        let dataType = configs.DATA_TYPE;
        return {
            viewName: "thong-ke-tien-te",
            dateRange: {
                startDate: moment.utc(Date.now()).startOf('month').toISOString().substr(0, 10),
                endDate: moment.utc(Date.now()).endOf('month').toISOString().substr(0, 10),
            },
            menuStart: false,
            menuEnd: false,
        };
    },
    computed: {
        ...mapState({
            items: state => state.report.orderMoney.data,
            summary: state => state.report.orderMoney.summary,
            loading: state => state.report.loading,
            staffs: state =>
                _.filter(state.commons.all_users, function(o) {
                return ((o.RoleType & configs.ROLE_TYPE.Purchase) == configs.ROLE_TYPE.Purchase) && (((state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin) || state.authentication.user.userid == o.ID);
            }),
        }),
        computedStartDateFormatted () {
            return moment(this.dateRange.startDate).format(configs.SHORT_DATE_FORMAT);
        },
        computedEndDateFormatted () {
            return moment(this.dateRange.endDate).format(configs.SHORT_DATE_FORMAT);
        },
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        dic_staff() {
            let dic = {};
            let zeroItem = _.find(this.summary, {'BuyerID': 0});
            if(zeroItem){
                dic[0] = '';
            }
            
            for (let i = 0; i < this.staffs.length; i++) {
                const item = this.staffs[i];
                dic[item.ID] = item.FullName;
            }
            return dic;
        }
    },
    methods: {
        filter_data: function () {
            var param = {
                startTime: moment(this.dateRange.startDate).format(configs.SQL_DATE_FORMAT),
                endTime: moment(this.dateRange.endDate).format(configs.SQL_DATE_FORMAT),
                sortBy: 'BuyDate'
            };
            this.$store.dispatch("report/getOrderMoney", param);
        },
        saveItem(item) {
            item.BoughtDate =item.BuyDate;
            var p = {
                startTime: moment(this.dateRange.startDate).format(configs.SQL_DATE_FORMAT),
                endTime: moment(this.dateRange.endDate).format(configs.SQL_DATE_FORMAT),
                sortBy: 'BuyDate'
            };
            this.$store.dispatch("report/setOrderMoney", {
                data: item,
                id: item.ID,
                param: p
            });
        },
        getSummary(key) {
            const item = _.find(this.summary, {
                index: key
            });
            return item ? item.Total : "";
        },
    },
    mounted() {
        this.$store.dispatch("commons/getAllUsers");
        this.filter_data();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    }
};
</script>